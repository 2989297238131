<template>
  <div class="LegoSet">
    <div class="container py-2">
      <div v-if="!loading" class="row">
        <div class="card py-2">
          <div class="row g-0">
            <div class="col-md-4">
              <div
                id="billedekarusel"
                class="carousel carousel-dark slide"
                data-bs-ride="false"
                data-bs-interval="false"
                style="max-height: 400px;">
                <div class="carousel-indicators" v-if="egneBilleder">
                  <button type="button" data-bs-target="#billedekarusel" data-bs-slide-to="0" class="active" />
                  <button v-for="(billede, index) in billeder" :key="index" type="button" data-bs-target="#billedekarusel" :data-bs-slide-to="index+1" ></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active" style="overflow: inherit;">
                    <img
                        :src="saet.url"
                        class="img-fluid rounded d-block"
                        :alt="saet.nummer"
                        style="object-fit: contain;"
                    />
                  </div>
                  <div v-for="(billede) in billeder" :key="billede.status.basename" class="carousel-item">
                    <img
                      :src="billede.data"
                      v-if="billede.data"
                      class="img-fluid rounded d-block w-100"
                      :alt="billede.status.basename"
                      style="object-fit: contain;"
                    />
                  </div>
                </div>
                <button
                  v-if="egneBilleder"
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#billedekarusel"
                  data-bs-slide="prev"
                >
                  <span class="carousel-control-prev-icon"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  v-if="egneBilleder"
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#billedekarusel"
                  data-bs-slide="next"
                >
                  <span class="carousel-control-next-icon"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <h5 class="card-title">{{ saet.navn }}</h5>
                <span class="badge rounded-pill bg-info">
                  <span
                    v-if="saet.harTemaTemas[0].undertemaTilTemas.length > 0"
                  >
                    {{ saet.harTemaTemas[0].undertemaTilTemas[0].navn }} &gt;
                  </span>
                  {{ saet?.harTemaTemas[0].navn }}
                </span>
                &nbsp;
                <span class="badge rounded-pill bg-success">
                  <font-awesome-icon icon="hashtag" />
                  {{ saet.nummer }}
                </span>
                &nbsp;
                <span class="badge rounded-pill bg-primary text-light">
                  <font-awesome-icon :icon="['far', 'calendar']" />
                  {{ saet.aarstal }}
                </span>
                &nbsp;
                <span class="badge rounded-pill bg-dark text-light">
                  {{ saet.antalKlodser }}
                </span>
                <span v-if="Number(saet.version) > 1">
                  <span class="badge rounded-pill bg-warning text-light">
                    v{{ saet.version }}
                  </span>
                </span>
                <div class="card-text form-floating mt-2">
                  <input
                    type="text"
                    v-model="attributter.kommentar"
                    class="form-control"
                    id="kommentarInput"
                    placeholder="Skriv en kommentar..."
                  />
                  <label for="kommentarInput">Kommentar</label>
                </div>
                <div class="card-text form-check form-switch">
                  <input
                    type="checkbox"
                    v-model="attributter.fotograferet"
                    class="form-check-input"
                    role="switch"
                    id="fotograferetInput"
                  />
                  <label for="fotograferetInput" class="form-check-label">
                    Fotograferet
                  </label>
                </div>
                <div class="row">
                  <div class="col-md-5 ms-3 py-2 border border-1">
                    <p class="text-muted">Kilder:</p>
                    <div
                      class="row my-1 align-items-center"
                      v-for="(kilde, name) in attributter.kilder"
                      :key="name"
                    >
                      <div class="col-6">
                        <label :for="name" class="form-label">
                          {{ name }}:
                        </label>
                      </div>
                      <div class="col-4">
                        <input
                          type="number"
                          :id="name"
                          class="form-control"
                          v-model="attributter.kilder[name]"
                        />
                      </div>
                      <div class="col-1">
                        <font-awesome-icon
                          v-if="!erValidData"
                          :icon="['fas', 'circle-exclamation']"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5 me-3 ms-auto py-2 border">
                    <p class="text-muted">Placeringer:</p>
                    <div
                      class="row my-1 align-items-center"
                      v-for="(sted, name) in attributter.placeringer"
                      :key="name"
                    >
                      <div class="col-6">
                        <label :for="name" class="form-label">
                          {{ name }}:
                        </label>
                      </div>
                      <div class="col-4">
                        <input
                          type="number"
                          :id="name"
                          class="form-control"
                          v-model="attributter.placeringer[name]"
                        />
                      </div>
                      <div class="col-1">
                        <font-awesome-icon
                          v-if="!erValidData"
                          :icon="['fas', 'circle-exclamation']"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-2 d-grid gap-2 d-md-flex justify-content-md-end">
                  <button
                    class="btn btn-secondary me-md-2 btn-sm"
                    type="button"
                    @click="nulstil"
                    :disabled="!erOpdateret"
                  >
                    Nulstil
                  </button>
                  <button
                    class="btn btn-primary btn-sm"
                    type="button"
                    @click="opdaterSaet(saet.uuid, attributter)"
                    :disabled="!erOpdateret || !erValidData"
                  >
                    Opdater
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul class="nav nav-tabs mt-3">
          <li class="nav-item">
            <router-link
              class="nav-link"
              :to="{ name: 'seatklodser', params: { uuid: uuid } }"
            >
              Klodser
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              :to="{
                name: 'seatfigurer',
                params: { uuid: uuid, visAntalISaet: true }
              }"
            >
              Minifigurer
            </router-link>
          </li>
        </ul>
        <router-view></router-view>
      </div>
      <div v-else>
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  FJERN_KILDE_MUTATION,
  FJERN_LOKATION_MUTATION,
  KILDER_OPSLAG,
  LOKATIONER_OPSLAG,
  OPDATER_KILDE_MUTATION,
  OPDATER_LOKATION_MUTATION,
  OPDATER_SAET_MUTATION,
  SAET_FOR_UUID_OPSLAG
} from "@/constants/graphql";
import { useMutation, useQuery, useResult } from "@vue/apollo-composable";
import {computed, defineComponent, inject, onMounted, Ref, ref, watch} from "vue";
import {
  KildeEdge,
  KildeNode,
  Legosaet,
  LokationNode,
  NumberMap
} from "@/types/legotyper";
import {BufferLike, FileStat, ResponseDataDetailed, WebDAVClient} from "webdav";

export default defineComponent({
  props: ["uuid", "setnum"],

  name: "LegoSet",
  setup(props) {
    interface Attributter {
      fotograferet: boolean;
      kommentar: string | null;
      kilder: NumberMap;
      placeringer: NumberMap;
    }
    interface Options {
      enabled: boolean;
    }
    interface Billede {
      data?: string;
      status?: FileStat;
    }

    const webdav = inject('webdavclient') as WebDAVClient;

    const billeder: Ref<Billede[]> = ref([]);

    const defaultLegosaet: Legosaet = {
      __typename: "Set",
      aarstal: "",
      antalKlodser: 0,
      fotograferet: false,
      harTemaTemas: [],
      kildesHarSetConnection: {
        __typename: "SetKildesHarSetConnection",
        edges: []
      },
      kommentar: null,
      navn: "",
      nummer: "",
      placeretLokationsConnection: {
        __typename: "SetPlaceretLokationsConnection",
        edges: []
      },
      url: null,
      uuid: "",
      version: ""
    };
    const attributter: Ref<Attributter> = ref({
      fotograferet: false,
      kommentar: null,
      kilder: {},
      placeringer: {}
    });

    const oprindelige: Ref<Attributter> = ref({
      fotograferet: false,
      kommentar: null,
      kilder: {},
      placeringer: {}
    });

    const { result: kilderResult, loading: kilderLoading } = useQuery<
      KildeNode[]
    >(KILDER_OPSLAG);

    const muligeKilder = useResult(kilderResult, []);

    const { result: lokationResult, loading: lokationLoading } = useQuery<
      LokationNode[]
    >(LOKATIONER_OPSLAG);
    const muligeLokationer = useResult(lokationResult, []);

    const { result: saetResult, loading: saetLoading, onResult: saetIndlaest } = useQuery(
      SAET_FOR_UUID_OPSLAG,
      props,
      () => {
        const options: Options = {
          enabled: false
        };
        const enabled =
            muligeLokationer.value.length > 0 && muligeKilder.value.length >0 &&
            !lokationLoading.value && !kilderLoading.value;
        options.enabled = enabled;
        return options;
      }

    );
    const saet = useResult(saetResult, [defaultLegosaet], data => data.saet[0]);

    function antal(navn: string, edges: KildeEdge[]): number {
      const edge = edges.find((edge: KildeEdge) => {
        return edge.node.navn === navn;
      });
      return edge ? Number(edge.antal) : 0;
    }

    async function hentBilledeliste() {
      const stinavn: string = "/" + props.setnum;
      if (await webdav.exists( stinavn ) === true) {
        webdav.getDirectoryContents(stinavn, { glob: "*.{jpeg,jpg,png,gif}" }).then((result) => {
          const mappeStatus: FileStat[] = result as FileStat[];
          for (const status of mappeStatus) {
            const billede: Billede = { status: status };
            webdav.getFileContents(status.filename).then((data) => {
              const buffer: ArrayBuffer = data as ArrayBuffer;
              const blob = new Blob([data as BufferLike], {'type': 'image/jpeg'});
              const reader = new FileReader();
              reader.readAsDataURL(blob)
              reader.onloadend = () => {
                billede.data = reader.result as string;
                billeder.value.push(billede);
              }
            })
          }
        })
        .catch((error) => { console.error(`Fejl ved at hente billede ${stinavn}: ${error.message}`)});
      }
    };

    const egneBilleder: Ref<boolean> = computed<boolean>(() => {
      return billeder.value.length > 0;
    })

    function antalKilderCallback(map: NumberMap, node: KildeNode): NumberMap {
      if (saet.value) {
        map[node.navn] = antal(
          node.navn,
          saet.value.kildesHarSetConnection.edges
        );
      }
      return map;
    }

    function antalLokationerCallback(
      map: NumberMap,
      node: LokationNode
    ): NumberMap {
      map[node.navn] = antal(
        node.navn,
        saet.value.placeretLokationsConnection.edges
      );
      return map;
    }

    function assignAttributter(source: Attributter): Attributter {
      const destination: Attributter = Object.assign({}, source);
      destination.kilder = Object.assign({}, source.kilder);
      destination.placeringer = Object.assign({}, source.placeringer);
      return destination;
    }

    function opdaterAttributter(data: Legosaet) {
      attributter.value.fotograferet = data.fotograferet;
      attributter.value.kommentar = data.kommentar;
      attributter.value.kilder = (muligeKilder.value as KildeNode[]).reduce<
        NumberMap
      >(antalKilderCallback, {});
      attributter.value.placeringer = (muligeLokationer.value as LokationNode[]).reduce<
        NumberMap
      >(antalLokationerCallback, {});
      oprindelige.value = Object.assign(
        {},
        assignAttributter(attributter.value)
      );
      }

    saetIndlaest(({ data }) => {
      opdaterAttributter(data.saet[0]);
    });

    const erOpdateret = computed(() => {
      return !(
        ((attributter.value.fotograferet && oprindelige.value.fotograferet) ||
          (!attributter.value.fotograferet &&
            !oprindelige.value.fotograferet)) &&
        (attributter.value.kommentar === oprindelige.value.kommentar ||
          (!attributter.value.kommentar && !oprindelige.value.kommentar)) &&
        Object.entries(attributter.value.kilder).every(
          ([kilde, antal]) => oprindelige.value.kilder[kilde] === antal
        ) &&
        Object.entries(attributter.value.placeringer).every(
          ([sted, antal]) => oprindelige.value.placeringer[sted] === antal
        )
      );
    });

    const erValidData = computed(() => {
      return (
        Object.values(attributter.value.kilder).reduce((a, b) => a + b) ===
        Object.values(attributter.value.placeringer).reduce((a, b) => a + b)
      );
    });

    function nulstil() {
      attributter.value = Object.assign(
        {},
        assignAttributter(oprindelige.value)
      );
    }

    const { mutate: opdaterKilde } = useMutation(OPDATER_KILDE_MUTATION);
    const { mutate: sletKilde } = useMutation(FJERN_KILDE_MUTATION);
    const { mutate: opdaterLokation } = useMutation(OPDATER_LOKATION_MUTATION);
    const { mutate: sletLokation } = useMutation(FJERN_LOKATION_MUTATION);
    const { mutate: opdaterLegosaet, onDone } = useMutation(
      OPDATER_SAET_MUTATION
    );

    function opdaterSaet(uuid: string, attributter: Attributter) {
      Object.entries(attributter.kilder).forEach(kilde => {
        const data = {
          uuid: uuid,
          kilde: kilde[0],
          antal: kilde[1].toString()
        };
        if (kilde[1] > 0) {
          opdaterKilde(data);
        } else {
          sletKilde(data);
        }
      });
      Object.entries(attributter.placeringer).forEach(lokation => {
        const data = {
          uuid: uuid,
          lokation: lokation[0],
          antal: lokation[1].toString()
        };
        if (lokation[1] > 0) {
          opdaterLokation(data);
        } else {
          sletLokation(data);
        }
      });
      opdaterLegosaet({
        uuid: uuid,
        fotograferet: attributter.fotograferet,
        kommentar: attributter.kommentar
      });
    }

    onDone(data => {
      opdaterAttributter(data.data.updateSets.saet[0]);
    });

    const loading = computed((): boolean => {
      return kilderLoading.value || lokationLoading.value || saetLoading.value;
    });

    onMounted(() => {
      if (saet.value.nummer == props.setnum) {
        opdaterAttributter(saet.value as Legosaet);
      }
      hentBilledeliste();
    });

    return {
      attributter,
      billeder,
      egneBilleder,
      erOpdateret,
      erValidData,
      loading,
      opdaterSaet,
      oprindelige,
      nulstil,
      saet
    };
  }
});
</script>
