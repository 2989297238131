import { createApp, provide, h } from "vue";
import App from "./App.vue";
import { apolloClient } from "@/apollo.provider";
import router from "./router";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import {
  faCircleCheck,
  faCircleExclamation,
  faCamera,
  faHashtag,
  faImage,
  faMinus,
  faPlus,
  faRightFromBracket,
  faRightToBracket,
  faXmark
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { createAuth0 } from "@auth0/auth0-vue";
import webdavclient from "@/webdavclient";

library.add(
  faCalendar,
  faCamera,
  faCircleCheck,
  faCircleExclamation,
  faHashtag,
  faImage,
  faMinus,
  faPlus,
  faRightFromBracket,
  faRightToBracket,
  faXmark
);

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  render: () => h(App)
});

app
  .use(
    createAuth0({
      domain: process.env.VUE_APP_AUTH0_DOMAIN
        ? process.env.VUE_APP_AUTH0_DOMAIN
        : "",
      client_id: process.env.VUE_APP_AUTH0_CLIENT_ID // eslint-disable-line @typescript-eslint/camelcase
        ? process.env.VUE_APP_AUTH0_CLIENT_ID
        : "",
      redirect_uri: window.location.origin, // eslint-disable-line @typescript-eslint/camelcase
      audience: process.env.VUE_APP_AUTH0_AUDIENCE
    })
  )
  .use(router)
  .use(webdavclient)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
