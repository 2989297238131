
import {
  FJERN_KILDE_MUTATION,
  FJERN_LOKATION_MUTATION,
  KILDER_OPSLAG,
  LOKATIONER_OPSLAG,
  OPDATER_KILDE_MUTATION,
  OPDATER_LOKATION_MUTATION,
  OPDATER_SAET_MUTATION,
  SAET_FOR_UUID_OPSLAG
} from "@/constants/graphql";
import { useMutation, useQuery, useResult } from "@vue/apollo-composable";
import {computed, defineComponent, inject, onMounted, Ref, ref, watch} from "vue";
import {
  KildeEdge,
  KildeNode,
  Legosaet,
  LokationNode,
  NumberMap
} from "@/types/legotyper";
import {BufferLike, FileStat, ResponseDataDetailed, WebDAVClient} from "webdav";

export default defineComponent({
  props: ["uuid", "setnum"],

  name: "LegoSet",
  setup(props) {
    interface Attributter {
      fotograferet: boolean;
      kommentar: string | null;
      kilder: NumberMap;
      placeringer: NumberMap;
    }
    interface Options {
      enabled: boolean;
    }
    interface Billede {
      data?: string;
      status?: FileStat;
    }

    const webdav = inject('webdavclient') as WebDAVClient;

    const billeder: Ref<Billede[]> = ref([]);

    const defaultLegosaet: Legosaet = {
      __typename: "Set",
      aarstal: "",
      antalKlodser: 0,
      fotograferet: false,
      harTemaTemas: [],
      kildesHarSetConnection: {
        __typename: "SetKildesHarSetConnection",
        edges: []
      },
      kommentar: null,
      navn: "",
      nummer: "",
      placeretLokationsConnection: {
        __typename: "SetPlaceretLokationsConnection",
        edges: []
      },
      url: null,
      uuid: "",
      version: ""
    };
    const attributter: Ref<Attributter> = ref({
      fotograferet: false,
      kommentar: null,
      kilder: {},
      placeringer: {}
    });

    const oprindelige: Ref<Attributter> = ref({
      fotograferet: false,
      kommentar: null,
      kilder: {},
      placeringer: {}
    });

    const { result: kilderResult, loading: kilderLoading } = useQuery<
      KildeNode[]
    >(KILDER_OPSLAG);

    const muligeKilder = useResult(kilderResult, []);

    const { result: lokationResult, loading: lokationLoading } = useQuery<
      LokationNode[]
    >(LOKATIONER_OPSLAG);
    const muligeLokationer = useResult(lokationResult, []);

    const { result: saetResult, loading: saetLoading, onResult: saetIndlaest } = useQuery(
      SAET_FOR_UUID_OPSLAG,
      props,
      () => {
        const options: Options = {
          enabled: false
        };
        const enabled =
            muligeLokationer.value.length > 0 && muligeKilder.value.length >0 &&
            !lokationLoading.value && !kilderLoading.value;
        options.enabled = enabled;
        return options;
      }

    );
    const saet = useResult(saetResult, [defaultLegosaet], data => data.saet[0]);

    function antal(navn: string, edges: KildeEdge[]): number {
      const edge = edges.find((edge: KildeEdge) => {
        return edge.node.navn === navn;
      });
      return edge ? Number(edge.antal) : 0;
    }

    async function hentBilledeliste() {
      const stinavn: string = "/" + props.setnum;
      if (await webdav.exists( stinavn ) === true) {
        webdav.getDirectoryContents(stinavn, { glob: "*.{jpeg,jpg,png,gif}" }).then((result) => {
          const mappeStatus: FileStat[] = result as FileStat[];
          for (const status of mappeStatus) {
            const billede: Billede = { status: status };
            webdav.getFileContents(status.filename).then((data) => {
              const buffer: ArrayBuffer = data as ArrayBuffer;
              const blob = new Blob([data as BufferLike], {'type': 'image/jpeg'});
              const reader = new FileReader();
              reader.readAsDataURL(blob)
              reader.onloadend = () => {
                billede.data = reader.result as string;
                billeder.value.push(billede);
              }
            })
          }
        })
        .catch((error) => { console.error(`Fejl ved at hente billede ${stinavn}: ${error.message}`)});
      }
    };

    const egneBilleder: Ref<boolean> = computed<boolean>(() => {
      return billeder.value.length > 0;
    })

    function antalKilderCallback(map: NumberMap, node: KildeNode): NumberMap {
      if (saet.value) {
        map[node.navn] = antal(
          node.navn,
          saet.value.kildesHarSetConnection.edges
        );
      }
      return map;
    }

    function antalLokationerCallback(
      map: NumberMap,
      node: LokationNode
    ): NumberMap {
      map[node.navn] = antal(
        node.navn,
        saet.value.placeretLokationsConnection.edges
      );
      return map;
    }

    function assignAttributter(source: Attributter): Attributter {
      const destination: Attributter = Object.assign({}, source);
      destination.kilder = Object.assign({}, source.kilder);
      destination.placeringer = Object.assign({}, source.placeringer);
      return destination;
    }

    function opdaterAttributter(data: Legosaet) {
      attributter.value.fotograferet = data.fotograferet;
      attributter.value.kommentar = data.kommentar;
      attributter.value.kilder = (muligeKilder.value as KildeNode[]).reduce<
        NumberMap
      >(antalKilderCallback, {});
      attributter.value.placeringer = (muligeLokationer.value as LokationNode[]).reduce<
        NumberMap
      >(antalLokationerCallback, {});
      oprindelige.value = Object.assign(
        {},
        assignAttributter(attributter.value)
      );
      }

    saetIndlaest(({ data }) => {
      opdaterAttributter(data.saet[0]);
    });

    const erOpdateret = computed(() => {
      return !(
        ((attributter.value.fotograferet && oprindelige.value.fotograferet) ||
          (!attributter.value.fotograferet &&
            !oprindelige.value.fotograferet)) &&
        (attributter.value.kommentar === oprindelige.value.kommentar ||
          (!attributter.value.kommentar && !oprindelige.value.kommentar)) &&
        Object.entries(attributter.value.kilder).every(
          ([kilde, antal]) => oprindelige.value.kilder[kilde] === antal
        ) &&
        Object.entries(attributter.value.placeringer).every(
          ([sted, antal]) => oprindelige.value.placeringer[sted] === antal
        )
      );
    });

    const erValidData = computed(() => {
      return (
        Object.values(attributter.value.kilder).reduce((a, b) => a + b) ===
        Object.values(attributter.value.placeringer).reduce((a, b) => a + b)
      );
    });

    function nulstil() {
      attributter.value = Object.assign(
        {},
        assignAttributter(oprindelige.value)
      );
    }

    const { mutate: opdaterKilde } = useMutation(OPDATER_KILDE_MUTATION);
    const { mutate: sletKilde } = useMutation(FJERN_KILDE_MUTATION);
    const { mutate: opdaterLokation } = useMutation(OPDATER_LOKATION_MUTATION);
    const { mutate: sletLokation } = useMutation(FJERN_LOKATION_MUTATION);
    const { mutate: opdaterLegosaet, onDone } = useMutation(
      OPDATER_SAET_MUTATION
    );

    function opdaterSaet(uuid: string, attributter: Attributter) {
      Object.entries(attributter.kilder).forEach(kilde => {
        const data = {
          uuid: uuid,
          kilde: kilde[0],
          antal: kilde[1].toString()
        };
        if (kilde[1] > 0) {
          opdaterKilde(data);
        } else {
          sletKilde(data);
        }
      });
      Object.entries(attributter.placeringer).forEach(lokation => {
        const data = {
          uuid: uuid,
          lokation: lokation[0],
          antal: lokation[1].toString()
        };
        if (lokation[1] > 0) {
          opdaterLokation(data);
        } else {
          sletLokation(data);
        }
      });
      opdaterLegosaet({
        uuid: uuid,
        fotograferet: attributter.fotograferet,
        kommentar: attributter.kommentar
      });
    }

    onDone(data => {
      opdaterAttributter(data.data.updateSets.saet[0]);
    });

    const loading = computed((): boolean => {
      return kilderLoading.value || lokationLoading.value || saetLoading.value;
    });

    onMounted(() => {
      if (saet.value.nummer == props.setnum) {
        opdaterAttributter(saet.value as Legosaet);
      }
      hentBilledeliste();
    });

    return {
      attributter,
      billeder,
      egneBilleder,
      erOpdateret,
      erValidData,
      loading,
      opdaterSaet,
      oprindelige,
      nulstil,
      saet
    };
  }
});
