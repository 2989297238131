import {createClient} from "webdav";
import {App} from "vue";

export default {
  install: (vue: App) => {
    const webdavclient = createClient(
    process.env.VUE_APP_WEBDAV_URL ? process.env.VUE_APP_WEBDAV_URL : "",
      {
          username: process.env.VUE_APP_WEBDAV_USER,
          password: process.env.VUE_APP_WEBDAV_PASSWORD
      }
    );
    vue.provide('webdavclient', webdavclient);
  }
}