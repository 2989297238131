import { InMemoryCache, ApolloClient, HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const cache = new InMemoryCache({
  typePolicies: {
    Set: {
      keyFields: ["uuid"]
    },
    Figur: {
      keyFields: ["uuid"]
    },
    Klods: {
      keyFields: ["uuid"]
    },
    Query: {
      fields: {
        Set: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: "Set",
              uuid: args?.uuid
            });
          }
        }
      }
    }
  }
});

const httpLink = new HttpLink({ uri: process.env.VUE_APP_API_BASE_URL });

const authMiddleware = setContext(async () => {
  const token = localStorage.getItem("accesstoken");
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
});

export const apolloClient = new ApolloClient({
  cache,
  link: authMiddleware.concat(httpLink),
  connectToDevTools: true
});
