import gql from "graphql-tag";

export const SAET_FELTER_FRAGMENT = gql`
  fragment SaetFelterFragment on sets {
    uuid
    nummer
    navn
    aarstal
    version
    kommentar
    fotograferet
    url
    antalKlodser
  }
`;

export const FJERN_KILDE_MUTATION = gql`
  mutation fjernKilde($uuid: ID!, $kilde: String!) {
    updateSets(
      where: { uuid: $uuid }
      disconnect: { kildesHarSet: { where: { node: { navn: $kilde } } } }
    ) {
      sets {
        uuid
      }
    }
  }
`;

export const FJERN_LOKATION_MUTATION = gql`
  mutation fjernLokation($uuid: ID!, $lokation: String!) {
    updateSets(
      where: { uuid: $uuid }
      disconnect: {
        placeretLokations: { where: { node: { navn: $lokation } } }
      }
    ) {
      sets {
        uuid
      }
    }
  }
`;

export const OPDATER_KILDE_MUTATION = gql`
  mutation opdaterKilde($uuid: ID!, $kilde: String!, $antal: BigInt!) {
    updateSets(
      where: { uuid: $uuid }
      connect: {
        kildesHarSet: {
          where: { node: { navn: $kilde } }
          edge: { antal: $antal }
        }
      }
    ) {
      sets {
        uuid
      }
    }
  }
`;

export const OPDATER_LOKATION_MUTATION = gql`
  mutation opdaterLokation($uuid: ID!, $lokation: String!, $antal: BigInt!) {
    updateSets(
      where: { uuid: $uuid }
      connect: {
        placeretLokations: {
          where: { node: { navn: $lokation } }
          edge: { antal: $antal }
        }
      }
    ) {
      sets {
        uuid
      }
    }
  }
`;

export const OPDATER_KLODS_MUTATION = gql`
  mutation opdaterKlods($uuid: ID!, $difference: BigInt) {
    updateKlods(where: { uuid: $uuid }, update: { difference: $difference }) {
      klods {
        uuid
        navn
        url
        antalDele
        antalFigurDele
        beregnetAntal
        difference
        erDelDels {
          nummer
          navn
          harKategoriKategoris {
            navn
          }
        }
        harFarvenFarves {
          navn
          id
        }
      }
    }
  }
`;

export const OPDATER_SAET_MUTATION = gql`
  mutation opdaterSaet($uuid: ID!, $kommentar: String, $fotograferet: Boolean) {
    updateSets(
      where: { uuid: $uuid }
      update: { kommentar: $kommentar, fotograferet: $fotograferet }
    ) {
      saet: sets {
        uuid
        nummer
        navn
        aarstal
        version
        kommentar
        fotograferet
        url
        harTemaTemas {
          id
          navn
          undertemaTilTemas {
            id
            navn
          }
        }
        kildesHarSetConnection {
          edges {
            antal
            node {
              navn
            }
          }
        }
        placeretLokationsConnection {
          edges {
            antal
            node {
              navn
            }
          }
        }
        antalKlodser
      }
    }
  }
`;

export const OPDATER_FIGUR_MUTATION = gql`
  mutation opdaterFigur($uuid: ID!, $difference: BigInt!) {
    updateFigurs(where: { uuid: $uuid }, update: { difference: $difference }) {
      figurer: figurs {
        __typename
        uuid
        nummer
        antalFigurer
        difference
        bestaarAfKlodsConnection {
          edges {
            node {
              __typename
              uuid
              antalDele
              antalFigurDele
              beregnetAntal
            }
          }
        }
      }
    }
  }
`;

export const FARVER_OPSLAG = gql`
  query farveOpslag {
    farver: farves {
      __typename
      id
      navn
      rgb
    }
  }
`;

export const KILDER_OPSLAG = gql`
  query kilderOpslag {
    muligeKilder: kildes {
      navn
    }
  }
`;

export const LOKATIONER_OPSLAG = gql`
  query lokationerOpslag {
    muligePlaceringer: lokations {
      navn
    }
  }
`;

export const SAET_FOR_UUID_OPSLAG = gql`
  query seatOpslag($uuid: ID!) {
    saet: sets(where: { uuid: $uuid }) {
      uuid
      nummer
      navn
      aarstal
      version
      kommentar
      fotograferet
      url
      harTemaTemas {
        id
        navn
        undertemaTilTemas {
          id
          navn
        }
      }
      kildesHarSetConnection {
        edges {
          antal
          node {
            navn
          }
        }
      }
      placeretLokationsConnection {
        edges {
          antal
          node {
            navn
          }
        }
      }
      antalKlodser
    }
  }
`;

export const KLODSER_I_SAET_FOR_UUID_OPSLAG = gql`
  query klodserISaet($uuid: ID!) {
    klodser: klods(where: { setsIndeholder_SINGLE: { uuid: $uuid } }) {
      uuid
      navn
      url
      beregnetAntal
      difference
      erDelDels {
        nummer
        navn
        harKategoriKategoris {
          navn
        }
      }
      harFarvenFarves {
        navn
        id
      }
      setsIndeholderConnection(where: { node: { uuid: $uuid } }) {
        edges {
          antal
        }
      }
    }
  }
`;

export const KLODSER_I_FIGUR_FOR_UUID_OPSLAG = gql`
  query klodser($uuid: ID!) {
    klodser: klods(
      where: { figursBestaarAfConnection_SOME: { node: { uuid: $uuid } } }
    ) {
      __typename
      uuid
      navn
      url
      beregnetAntal
      difference
      erDelDels {
        __typename
        nummer
        navn
        harKategoriKategoris {
          navn
          __typename
        }
      }
      harFarvenFarves {
        __typename
        navn
        id
      }
      figursBestaarAfConnection(where: { node: { uuid: $uuid } }) {
        edges {
          antal
        }
      }
    }
  }
`;

export const KLODS_FOR_UUID_OPSLAG = gql`
  query klods( $uuid: ID! ) {
    klods: klods(
      where: { uuid: $uuid }
    ) {
      __typename
      uuid
      navn
      url
      element_id
      antalDele
      antalFigurDele
      beregnetAntal
      difference
      erDelDels {
        nummer
        navn
        harKategoriKategoris {
          navn
        }
      }
      harFarvenFarves {
        navn
        id
      }
    }
  }
`;

export const KLODS_OPSLAG = gql`
  query klodser(
    $soegBricklink: String
    $soegBrickowl: String
    $soegElement: String
    $soegLego: String
    $soegNavn: String
    $soegRebrickable: String
    $valgteFarver: [String!]
    $uuid: ID
    $valgteLokationer: [String!]
    $valgteKategorier: [String!]
    $medDifference: BigInt
    $offset: Int
    $max: Int
  ) {
    klodser: klods(
      where: {
        AND: [
          {
            OR: [
              { element_id_MATCHES: $soegElement }
              {
                erDelDels_SOME: {
                  AND: [
                    {
                      OR: [
                        { bricklinkNummer_MATCHES: $soegBricklink }
                        { brickowlNummer_MATCHES: $soegBrickowl }
                        { legoNummer_MATCHES: $soegLego }
                        { nummer_MATCHES: $soegRebrickable }
                        { navn_MATCHES: $soegNavn }
                      ]
                    }
                    {
                      harKategoriKategoris_SOME: { navn_IN: $valgteKategorier }
                    }
                  ]
                }
              }
            ]
          }
          { harFarvenFarves_SOME: { navn_IN: $valgteFarver } }
          { uuid: $uuid }
          {
            OR: [
              {
                setsIndeholder_SOME: {
                  placeretLokations_SOME: { navn_IN: $valgteLokationer }
                }
              }
              {
                figursBestaarAf_SOME: {
                  setsIndeholder_SOME: {
                    placeretLokations_SOME: { navn_IN: $valgteLokationer }
                  }
                }
              }
              { difference_LT: $medDifference }
              { difference_GT: $medDifference }
            ]
          }
        ]
      }
      options: { offset: $offset, limit: $max }
    ) {
      __typename
      uuid
      navn
      url
      element_id
      antalDele
      antalFigurDele
      beregnetAntal
      difference
      erDelDels {
        nummer
        navn
        harKategoriKategoris {
          navn
        }
      }
      harFarvenFarves {
        navn
        id
      }
    }
  }
`;

export const KLODS_OPSLAG_ANTAL = gql`
  query antalklodser(
    $soegBricklink: String
    $soegBrickowl: String
    $soegElement: String
    $soegLego: String
    $soegNavn: String
    $soegRebrickable: String
    $valgteFarver: [String!]
    $uuid: ID
    $valgteLokationer: [String!]
    $valgteKategorier: [String!]
    $medDifference: BigInt
  ) {
    klodsantal: klodsAggregate(
      where: {
        AND: [
          {
            OR: [
              { element_id_MATCHES: $soegElement }
              {
                erDelDels_SOME: {
                  AND: [
                    {
                      OR: [
                        { bricklinkNummer_MATCHES: $soegBricklink }
                        { brickowlNummer_MATCHES: $soegBrickowl }
                        { legoNummer_MATCHES: $soegLego }
                        { nummer_MATCHES: $soegRebrickable }
                        { navn_MATCHES: $soegNavn }
                      ]
                    }
                    {
                      harKategoriKategoris_SOME: { navn_IN: $valgteKategorier }
                    }
                  ]
                }
              }
            ]
          }
          { harFarvenFarves_SOME: { navn_IN: $valgteFarver } }
          { uuid: $uuid }
          {
            OR: [
              {
                setsIndeholder_SOME: {
                  placeretLokations_SOME: { navn_IN: $valgteLokationer }
                }
              }
              {
                figursBestaarAf_SOME: {
                  setsIndeholder_SOME: {
                    placeretLokations_SOME: { navn_IN: $valgteLokationer }
                  }
                }
              }
              { difference_LT: $medDifference }
              { difference_GT: $medDifference }
            ]
          }
        ]
      }
    ) {
      count
    }
  }
`;

export const FARVER_FOR_KLODS_OPSLAG = gql`
  query farver(
    $soegBricklink: String
    $soegBrickowl: String
    $soegElement: String
    $soegLego: String
    $soegNavn: String
    $soegRebrickable: String
    $valgteLokationer: [String!]
    $valgteKategorier: [String!]
    $colorId: BigInt
    $uuid: ID
    $medDifference: BigInt
  ) {
    farver: farves(
      where: {
        klodsHarFarven_SOME: {
          AND: [
            {
              OR: [
                { element_id_MATCHES: $soegElement }
                {
                  erDelDels_SOME: {
                    AND: [
                      {
                        OR: [
                          { bricklinkNummer_MATCHES: $soegBricklink }
                          { brickowlNummer_MATCHES: $soegBrickowl }
                          { legoNummer_MATCHES: $soegLego }
                          { nummer_MATCHES: $soegRebrickable }
                          { navn_MATCHES: $soegNavn }
                        ]
                      }
                      {
                        harKategoriKategoris_SOME: {
                          navn_IN: $valgteKategorier
                        }
                      }
                    ]
                  }
                }
              ]
            }
            { harFarvenFarves_SINGLE: { id: $colorId } }
            { uuid: $uuid }
            {
              OR: [
                {
                  setsIndeholder_SOME: {
                    placeretLokations_SOME: { navn_IN: $valgteLokationer }
                  }
                }
                {
                  figursBestaarAf_SOME: {
                    setsIndeholder_SOME: {
                      placeretLokations_SOME: { navn_IN: $valgteLokationer }
                    }
                  }
                }
                { difference_LT: $medDifference }
                { difference_GT: $medDifference }
              ]
            }
          ]
        }
      }
      options: { sort: { navn: ASC } }
    ) {
      __typename
      id
      navn
    }
  }
`;

export const KATEGORIER_FOR_KLODS_OPSLAG = gql`
  query kategorier(
    $soegBricklink: String
    $soegBrickowl: String
    $soegElement: String
    $soegLego: String
    $soegNavn: String
    $soegRebrickable: String
    $valgteFarver: [String!]
    $uuid: ID
    $valgteLokationer: [String!]
    $medDifference: BigInt
  ) {
    kategorier: kategoris(
      where: {
        delsHarKategori_SOME: {
          klodsErDel_SOME: {
            AND: [
              {
                OR: [
                  { element_id_MATCHES: $soegElement }
                  {
                    erDelDels_SOME: { bricklinkNummer_MATCHES: $soegBricklink }
                  }
                  { erDelDels_SOME: { brickowlNummer_MATCHES: $soegBrickowl } }
                  { erDelDels_SOME: { legoNummer_MATCHES: $soegLego } }
                  { erDelDels_SOME: { nummer_MATCHES: $soegRebrickable } }
                  { erDelDels_ALL: { navn_MATCHES: $soegNavn } }
                ]
              }
              { harFarvenFarves_SOME: { navn_IN: $valgteFarver } }
              { uuid: $uuid }
              {
                OR: [
                  {
                    setsIndeholder_SOME: {
                      placeretLokations_SOME: { navn_IN: $valgteLokationer }
                    }
                  }
                  {
                    figursBestaarAf_SOME: {
                      setsIndeholder_SOME: {
                        placeretLokations_SOME: { navn_IN: $valgteLokationer }
                      }
                    }
                  }
                  { difference_LT: $medDifference }
                  { difference_GT: $medDifference }
                ]
              }
            ]
          }
        }
      }
      options: { sort: { navn: ASC } }
    ) {
      __typename
      id
      navn
    }
  }
`;

export const FIGURER_I_SAET_FOR_UUID_OPSLAG = gql`
  query figurerISaet($uuid: ID!) {
    figurer: figurs(where: { setsIndeholder_SINGLE: { uuid: $uuid } }) {
      __typename
      uuid
      nummer
      navn
      katalognummer
      url
      antalFigurer
      setsIndeholderConnection(where: { node: { uuid: $uuid } }) {
        edges {
          antal
        }
      }
      bestaarAfKlods {
        __typename
        uuid
        navn
        url
        beregnetAntal
        difference
        erDelDels {
          __typename
          nummer
          navn
          harKategoriKategoris {
            navn
          }
        }
        harFarvenFarves {
          __typename
          navn
          id
        }
        setsIndeholderConnection(where: { node: { uuid: $uuid } }) {
          edges {
            antal
          }
        }
      }
    }
  }
`;

export const FIGURER_MED_KLODSER_OPSLAG = gql`
  query figurer(
    $uuid: ID
    $soegNavn: String
    $valgteLokationer: [String!]
    $offset: Int
    $max: Int
  ) {
    figurer: figurs(
      where: {
        AND: [
          { uuid: $uuid }
          { navn_MATCHES: $soegNavn }
          {
            setsIndeholder_SOME: {
              placeretLokations_SOME: { navn_IN: $valgteLokationer }
            }
          }
        ]
      }
      options: { offset: $offset, limit: $max }
    ) {
      __typename
      uuid
      nummer
      navn
      katalognummer
      url
      antalFigurer
      bestaarAfKlods {
        __typename
        uuid
        navn
        url
        beregnetAntal
        difference
        erDelDels {
          __typename
          nummer
          navn
          harKategoriKategoris {
            navn
          }
        }
        harFarvenFarves {
          __typename
          navn
          id
        }
      }
    }
  }
`;

export const FIGURER_OPSLAG = gql`
  query figur($uuid: ID!) {
    figur: figurs(where: { uuid: $uuid }) {
      __typename
      uuid
      nummer
      navn
      katalognummer
      url
      antalFigurer
      difference
    }
  }
`;

export const TEMAER_FOR_KILDER_OPSLAG = gql`
  query temaerKildeOpslag($setQuery: [String!]!) {
    muligeTemaer: temas(
      options: { sort: [{ antalSet: DESC }, { navn: ASC }] }
    ) {
      id
      navn
      antalSet(kilder: $setQuery)
    }
  }
`;
export const TEMAER_OPSLAG = gql`
  query temaerOpslag {
    temaer: temas(
      where: { undertemaTilTemasAggregate: { count: 0 } }
      options: { sort: { navn: ASC } }
    ) {
      id
      navn
      temasUndertemaTil(options: { sort: { navn: ASC } }) {
        id
        navn
        __typename
      }
      __typename
    }
  }
`;
