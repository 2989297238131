import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import LegoInventoryView from "../components/LegoInventory.vue";
import LegoSetView from "../components/LegoSet.vue";
import LegoParts from "../components/LegoParts.vue";
import LegoSetKlodser from "../components/LegoSetKlodser.vue";
import LegoSetMinifigurer from "../components/LegoSetMinifigurer.vue";
import LegoMinifigurer from "../components/LegoMinifigs.vue";
import { authGuard } from "@auth0/auth0-vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: LegoInventoryView,
    props: { lokation: "Hjemme" },
    beforeEnter: authGuard
  },
  {
    path: "/sets/:klodsUuid?",
    name: "klodsisaet",
    component: LegoInventoryView,
    props: true,
    beforeEnter: authGuard
  },
  {
    path: "/sets/:minifigUuid?",
    name: "minifigisaet",
    component: LegoInventoryView,
    props: true,
    beforeEnter: authGuard
  },
  {
    path: "/set/:uuid",
    name: "set",
    component: LegoSetView,
    props: true,
    beforeEnter: authGuard,
    children: [
      {
        path: "",
        redirect: "/set/:uuid/klodser"
      },
      {
        name: "seatklodser",
        path: "klodser",
        component: LegoSetKlodser,
        props: true
      },
      {
        name: "seatfigurer",
        path: "figurer",
        component: LegoSetMinifigurer,
        props: true
      }
    ]
  },
  {
    path: "/parts",
    name: "parts",
    component: LegoParts,
    beforeEnter: authGuard,
    props: true
  },
  {
    path: "/parts/:farvekode?",
    name: "klodsmedfarve",
    component: LegoParts,
    beforeEnter: authGuard,
    props: true
  },
  {
    path: "/parts/:soegetekst?",
    name: "klodsmednavn",
    component: LegoParts,
    beforeEnter: authGuard,
    props: true
  },
  {
    path: "/minifigs",
    name: "minifigurer",
    component: LegoMinifigurer,
    beforeEnter: authGuard
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
